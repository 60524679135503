import { ClassNames, Global } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import tw, { css } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-down.svg'
import { global } from '../styles/global'
import { max } from '../utils/breakpoints'
import { LinkType, RequiredLinkType } from '../utils/prop-types'
import Hamburger from './hamburger'
import Heading from './heading'
import LanguageToggle from './language-toggle'
import Link from './link'
import Text from './text'

const Dropdown = ({ item, localized }) => {
  return (
    <div
      css={css`
        height: 14.5rem;
        ${tw`hidden`}
        ${tw`lg:(flex pointer-events-none transition-opacity duration-300 ease-in-out opacity-0 fixed top-desktop-header inset-x-0 bg-white)`}
      `}
    >
      <ul css={[global`layout.grid`, global`layout.container`]}>
        {item.subLinks.map((i) => (
          <li key={i.label} css={tw`col-span-4 flex flex-col pr-6`}>
            <ClassNames>
              {({ css: cx }) => (
                <Link
                  to={i.link}
                  localized={localized}
                  activeClassName={cx(
                    css`
                      &:after {
                        ${tw`translate-x-0! opacity-100!`}
                      }
                    `
                  )}
                  style={[
                    css`
                      ${tw`pt-10 h-full`}
                      &:hover {
                        img {
                          ${tw`transform -translate-y-1`}
                        }
                      }
                    `,
                    global`base.hover-underline`(),
                  ]}
                  partiallyActive
                >
                  <img
                    src={i.image.url}
                    css={tw`transition-transform duration-300 ease-in-out max-w-nav-icon h-8 object-contain`}
                    alt=""
                  />
                  <div css={(global`typography.subtitle-lg`, tw`mt-5 font-bold text-primary-500`)}>
                    {i.label}
                  </div>
                  <Text
                    content={i.descriptionNode}
                    style={[global`typography.subtitle-lg`, tw`mt-4 text-grey`]}
                  />
                </Link>
              )}
            </ClassNames>
          </li>
        ))}
      </ul>
    </div>
  )
}

Dropdown.propTypes = {
  item: RequiredLinkType,
  localized: PropTypes.bool,
}

const DropdownNavItem = ({ item, localized }) => {
  return (
    <li
      css={[
        css`
          ${tw`h-full`}
          &:hover {
            button + div {
              ${tw`opacity-100 pointer-events-auto`}
            }
            svg {
              ${tw`transform -rotate-180`}
            }
          }
        `,
        global`base.hover-underline()`,
      ]}
    >
      <button type="button" css={tw`h-full flex items-center`}>
        <span css={[global`typography.subtitle-lg`, tw`font-medium text-left text-primary-500`]}>
          {item.label}
        </span>
        {item.subLinks && item.subLinks.length ? (
          <Caret css={tw`ml-2 fill-secondary transition-transform duration-300 ease-in-out`} />
        ) : null}
      </button>
      {item.subLinks && item.subLinks.length ? (
        <Dropdown item={item} localized={localized} />
      ) : null}
    </li>
  )
}

DropdownNavItem.propTypes = {
  item: RequiredLinkType,
  localized: PropTypes.bool,
}

const NavItem = ({ item, localized }) => (
  <ClassNames>
    {({ css: cx }) => (
      <li
        css={css`
          ${tw`h-full relative`}
        `}
      >
        <Link
          to={item.link}
          activeClassName={cx(
            css`
              &:after {
                ${tw`translate-x-0! opacity-100!`}
              }
            `
          )}
          style={[
            css`
              ${tw`h-full flex items-center`}
            `,
            global`base.hover-underline`(),
          ]}
          localized={localized}
          partiallyActive
        >
          <span css={[global`typography.subtitle-lg`, tw`font-medium text-primary-500`]}>
            {item.label}
          </span>
        </Link>
      </li>
    )}
  </ClassNames>
)

NavItem.propTypes = {
  item: RequiredLinkType,
  localized: PropTypes.bool,
}

const MobileMenu = React.forwardRef(
  ({ isOpened, primaryLinks, legal, socialMedia, copyright, forceCloseNav }, ref) => {
    return (
      <nav
        ref={ref}
        css={[
          global`layout.container`,
          tw`
            fixed top-mobile-header inset-x-0 bottom-0 pointer-events-none overflow-y-auto overflow-x-hidden
            bg-transparent transition-all duration-300 ease-out opacity-0 pt-6 pb-7 flex flex-col items-start
          `,
          isOpened && tw`flex opacity-100 bg-secondary-500 z-10 pointer-events-auto`,
        ]}
      >
        <LanguageToggle style={tw`mb-12`} />
        <div css={tw`flex flex-col justify-between`}>
{/*           <ul css={tw`space-y-4`}>
            {primaryLinks.map((link) => (
              <li key={link.label}>
                <Link to={link.link} onClick={forceCloseNav}>
                  <Heading
                    content={link.label}
                    headingType="h4"
                    style={tw`text-primary-500 font-medium`}
                  />
                </Link>
                {link.subLinks && link.subLinks.length ? (
                  <ul css={tw`space-y-3 pt-4 pb-9`}>
                    {link.subLinks.map((sublink) => (
                      <li key={sublink.label}>
                        <Link to={sublink.link} onClick={forceCloseNav}>
                          <Heading
                            content={sublink.label}
                            headingType="h1"
                            style={css`
                              font-size: 2.375rem;
                              ${tw`text-primary-500 font-light`}
                            `}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul> */}
          <footer css={tw`mt-16`}>
            <div css={tw`py-6 relative`}>
              <div
                css={css`
                  left: calc((100vw - 48px) / -19.2);
                  ${tw`bg-primary-500 bg-opacity-15 absolute w-screen h-px top-0`};
                `}
              />
              <Heading
                content="Legal information"
                headingType="h6"
                style={tw`text-primary-500 font-medium mb-2`}
              />
              <ul css={tw`flex flex-row space-x-6`}>
                {legal &&
                  legal.map((link) => (
                    <li key={legal.label}>
                      <Link to={legal.link} onClick={forceCloseNav}>
                        <Heading
                          content={legal.label}
                          headingType="h6"
                          style={tw`text-primary-500`}
                        />
                      </Link>
                    </li>
                  ))
                }
              </ul>
              <div
                css={css`
                  left: calc((100vw - 48px) / -19.2);
                  ${tw`bg-primary-500 bg-opacity-15 absolute w-screen h-px bottom-0`};
                `}
              />
            </div>
            <div css={tw`flex flex-row space-x-12 py-7`}>
              <Text content="Follow us" style={tw`text-base text-primary-500 font-medium`} />
              {socialMedia &&
                socialMedia.map((s) => (
                  <Link key={s.title} to={s.accountLink.link} style={tw`mr-12 last-of-type:mr-0`}>
                    <img
                      src={s.image.url}
                      title={s.title}
                      alt={s.alt}
                      css={css`
                        ${tw`max-h-social-icon`}
                        filter: brightness(0) saturate(100%) invert(15%) sepia(22%) saturate(3594%)
                          hue-rotate(223deg) brightness(88%) contrast(117%);
                      `}
                    />
                  </Link>
                ))}
            </div>
            <div
              css={css`
                line-height: 20px;
                letter-spacing: 0.0025em;
                ${tw`text-primary-500 text-sm`}
              `}
            >
              {copyright}
            </div>
          </footer>
        </div>
      </nav>
    )
  }
)

MobileMenu.propTypes = {
  isOpened: PropTypes.bool,
  primaryLinks: PropTypes.arrayOf(LinkType),
  legal: PropTypes.arrayOf(LinkType),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
  forceCloseNav: PropTypes.func,
}

const MainNav = ({
  primaryLinks,
  legal,
  socialMedia,
  copyright,
  isOpened,
  setIsOpened,
}) => {
  const itemsRef = React.createRef()

  const toggleNav = useCallback(
    (val) => {
      setIsOpened(val)
      if (!val) itemsRef.current.scrollTo(0, 0)
    },
    [itemsRef]
  )

  const forceCloseNav = useCallback(() => toggleNav(false), [toggleNav])

  return (
    <>
      {isOpened && (
        <Global
          styles={css`
            body {
              ${max.lg} {
                ${tw`overflow-hidden`}
              }
            }
          `}
        />
      )}
      <nav css={tw`hidden lg:(w-full h-full flex)`}>
        <ul css={tw`lg:(flex flex-row space-x-6) xl:space-x-12`}>
          {primaryLinks &&
            primaryLinks.map((l) =>
              l.subLinks && l.subLinks.length ? (
                <DropdownNavItem key={l.label} item={l} localized />
              ) : (
                <NavItem key={l.label} item={l} localized />
              )
            )}
        </ul>
      </nav>
      <div css={tw`flex justify-end items-center lg:hidden`}>
        <button type="button" onClick={() => toggleNav(!isOpened)}>
          <Hamburger isOpened={isOpened} />
        </button>
        <MobileMenu
          ref={itemsRef}
          isOpened={isOpened}
          primaryLinks={primaryLinks}
          legal={legal}
          socialMedia={socialMedia}
          copyright={copyright}
          forceCloseNav={forceCloseNav}
        />
      </div>
    </>
  )
}

MainNav.propTypes = {
  primaryLinks: PropTypes.arrayOf(LinkType),
  legal: PropTypes.arrayOf(LinkType),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
}

export default MainNav
